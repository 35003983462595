import { InterviewCalendarEventEndpoints } from '@ct-internal/api';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import APIRequest from '@shared/request';
import { INTERVIEW_CALENDAR_EVENT_KEY } from '@src/queries/InterviewCalendarEvent';

export const useICEPendingByApplication = ({
  options,
  params,
}: {
  params: InterviewCalendarEventEndpoints['getPendingByApplication']['params'];
  options?: UseQueryOptions<InterviewCalendarEventEndpoints['getPendingByApplication']['response']>;
}): UseQueryResult<InterviewCalendarEventEndpoints['getPendingByApplication']['response']> => {
  return useQuery({
    queryKey: [INTERVIEW_CALENDAR_EVENT_KEY, { application_id: params.application_id }],
    queryFn: () =>
      !params.application_id
        ? null
        : APIRequest(`/interview-calendar-events/by-pending-application/${params.application_id}`, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }),
    ...options,
  });
};
