import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const JobWebiteRegionVisibilityType = ['Country Group', 'Country'] as const;

export const JobWebiteRegionVisibilitySchema = z.object({
  job_id: z.string(),
  country_id: z.string().nullable(),
  country_group_id: z.number().nullable(),
  type: z.enum(JobWebiteRegionVisibilityType),
  dt_created: dateString,
  dt_updated: dateString,
});
export type JobWebiteRegionVisibility = z.infer<typeof JobWebiteRegionVisibilitySchema>;

export const JobWebsiteRegionVisibilityGroupSchema = z.object({
  countries_id: z.array(z.string()),
  group_name: z.string(),
  group_id: z.number(),
  type: z.enum(JobWebiteRegionVisibilityType),
});
export type JobWebsiteRegionVisibilityGroup = z.infer<typeof JobWebsiteRegionVisibilityGroupSchema>;

export const JobWebsiteRegionVisibilityCountrySchema = z.object({
  country_id: z.string(),
  country_name: z.string(),
  type: z.enum(JobWebiteRegionVisibilityType),
});
export type JobWebsiteRegionVisibilityCountry = z.infer<
  typeof JobWebsiteRegionVisibilityCountrySchema
>;

export const JobWebsiteRegionVisibilityReadSchema = z.union([
  JobWebsiteRegionVisibilityGroupSchema,
  JobWebsiteRegionVisibilityCountrySchema,
]);
export type JobWebsiteRegionVisibilityRead = z.infer<typeof JobWebsiteRegionVisibilityReadSchema>;
