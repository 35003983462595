import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TPersonSkillAsc, TPersonSkillAscSchema } from '../base';

export const CreatePersonSkillAscSchema = TPersonSkillAscSchema.pick({
  person_id: true,
  skill_id: true,
}).extend({
  level: z.number().optional(),
  is_favorite: z.boolean().optional(),
});

export type CreatePersonSkillAscParams = {};

export type CreatePersonSkillAscQuery = {};

export type CreatePersonSkillAscRequest = z.infer<typeof CreatePersonSkillAscSchema>;

export type CreatePersonSkillAscResponse = TPersonSkillAsc;

export type CreatePersonSkillAscEndpoint = Endpoint<
  Method.POST,
  '/api/v2/sql/person/skills',
  CreatePersonSkillAscResponse,
  CreatePersonSkillAscParams,
  CreatePersonSkillAscQuery,
  CreatePersonSkillAscRequest
>;
