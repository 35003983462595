import { TJobSchema } from '../table-schemas';
import { z } from 'zod';

export const JobCreateSchema = TJobSchema.pick({
  job_id: true,
  job_family_id: true,
  slug: true,
  owner_id: true,
  title: true,
  job_suffix: true,
  website_title: true,
  job_short_name: true,
  description: true,
  n_sort: true,
  on_deck_visibility: true,
  max_allowed_salary: true,
  executive_presence: true,
  dev_skiller_test_id: true,
  video_interview_audience: true,
  department_id: true,
  salary_min: true,
  salary_max: true,
  posted_salary_min: true,
  posted_salary_max: true,
  priority_id: true,
  fast_decision_mode: true,
  job_to_redirect_apps: true,
  voice_note_required: true,
  level: true,
  english_level: true,
  status: true,
  created_by: true,
}).partial({
  slug: true,
  owner_id: true,
  n_sort: true,
  executive_presence: true,
  dev_skiller_test_id: true,
  video_interview_audience: true,
  department_id: true,
  salary_max: true,
  salary_min: true,
  posted_salary_min: true,
  posted_salary_max: true,
  priority_id: true,
  job_to_redirect_apps: true,
  english_level: true,
  status: true,
  created_by: true,
});
export type JobCreate = z.infer<typeof JobCreateSchema>;
