import { LindyAIInterviewSummaryDTOSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';
import { z } from 'zod';

export const OnLindyAIMessageRequestSchema = LindyAIInterviewSummaryDTOSchema.partial({
  type: true,
});

export type OnLindyAIMessageRequest = z.infer<typeof OnLindyAIMessageRequestSchema>;

export type OnLindyAIMessageResponse = {};

export type OnLindyAIMessageEndpoint = Endpoint<
  Method.POST,
  `/api/lindy-ai`,
  OnLindyAIMessageResponse,
  undefined,
  undefined,
  OnLindyAIMessageRequest
>;
