import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { WebSFContact } from '../base';

export const WebSFContactFormTypeSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  emailAddress: z.string(),
  phoneNumber: z.coerce.string(),
  company: z.string().nullable(),
  jobTitle: z.string().nullable(),
  message: z.string(),
  subscribe: z.boolean().nullable(),
  agreePrivacyPolicy: z.boolean(),
  inquiryType: z.string(),
  timezone: z.string(),
});
export type WebSFContactFormType = z.infer<typeof WebSFContactFormTypeSchema>;

export const WebSFContactCreateRequestSchema = z.object({
  formData: WebSFContactFormTypeSchema,

  googleUserId: z.string().nullish(),

  utm_medium: z.string().nullish(),
  utm_source: z.string().nullish(),
  utm_campaign: z.string().nullish(),
  utm_term: z.string().nullish(),

  gclid: z.string().nullish(),
  browserDevice: z.string().nullish(),
  pageUrl: z.string().nullish(),
  ipAddress: z.string().nullish(),
});
export type WebSFContactCreateRequest = z.infer<typeof WebSFContactCreateRequestSchema>;

export type WebSFContactCreateResponse = WebSFContact;
export type WebSFContactCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/web-sf-contact/`,
  WebSFContactCreateResponse,
  undefined,
  undefined,
  WebSFContactCreateRequest
>;
