import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../../infrastructure';
import { JobWebsiteRegionVisibilityReadSchema } from '../../job-website-region-visibility';

export const JobWebsiteBoardItemSchema = z.object({
  id: z.string(),
  _id: z.string(),
  slug: z.string(),
  name: z.string(),
  priority: z.object({
    name: z.string(),
    priority: z.number(),
  }),
  skills: z.array(
    z.object({
      skill: z.string(),
      id: z.string(),
    }),
  ),
  videoInterview: z.number(),
  requiresVoiceNote: z.boolean(),
  hiring: z.number(),
  description: z.string(),
  updated_at: dateString,
  created_at: dateString,
  type: z.object({
    name: z.string(),
    updated_at: dateString,
    created_at: dateString,
  }),
  is_region_visibility_enabled: z.boolean(),
  region_visibility: JobWebsiteRegionVisibilityReadSchema.nullable(),
});
export type JobWebsiteBoardItem = z.infer<typeof JobWebsiteBoardItemSchema>;

export const JobWebsiteBoardQuerySchema = z.object({
  page: z.number().optional(),
  limit: z.number().optional(),
  slug: z.string().optional(),
});
export type JobWebsiteBoardQuery = z.infer<typeof JobWebsiteBoardQuerySchema>;

export type JobWebsiteBoardResponse = {
  page: number | null;
  limit: number | null;
  total: number;
  jobs: JobWebsiteBoardItem[];
};

export type JobWebsiteBoardEndpoint = Endpoint<
  Method.GET,
  '/api/v2/jobs/new/website',
  JobWebsiteBoardResponse,
  undefined,
  JobWebsiteBoardQuery,
  undefined
>;
