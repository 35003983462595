import { z } from 'zod';
import {
  Endpoint,
  Method,
  FilterOperations,
  ReportsQuerySchema,
  makeSorter,
} from '../../infrastructure';
import { PersonSchema, PersonMongoSchema } from './base';
import { ClientMongoSchema } from '../clients/base';
import { ProjectMongoSchema } from '../projects/base';
import { AssignmentMongoSchema } from '../assignment/base';
import { dateString } from '../../infrastructure/schema';

export type ListPeopleWithDeptEndpoint = Endpoint<
  Method.GET,
  '/api/v2/people/reports/simple-list-with-dept',
  ListPeopleWithDeptResponse,
  undefined,
  ListPeopleWithDeptQuery,
  undefined
>;

export const ListPeopleWithDeptFiltersSchema = z
  .object({
    department: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    peopleStatus: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    immigrationProgram: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    nameAndTags: z.record(z.enum([FilterOperations.EQUAL]), z.string()), // JSON as string
    yoe: z.record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      z.string(),
    ),
    terminationDate: z.record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      dateString,
    ),
    hireDate: z.record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      dateString,
    ),
    cultureContact: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    hiredBy: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    assignments: z.record(z.enum([FilterOperations.EQUAL]), z.string()), // JSON as string
    contractType: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    paidBy: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    country: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    city: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    state: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    skills: z.record(
      z.enum([FilterOperations.EQUAL]),
      z.string().or(
        z.array(
          z.object({
            _id: z.string(),
            skillLevel: z.number().or(z.string()).optional(),
            skillFavorite: z.boolean().or(z.string()).optional(),
          }),
        ),
      ),
    ),
    paymentOption: z.record(z.enum([FilterOperations.EQUAL]), z.string().or(z.array(z.string()))),
    payThroughDate: z.record(z.enum([FilterOperations.EQUAL]), dateString),
    gender: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    isBillable: z.record(z.enum([FilterOperations.EQUAL]), z.boolean().optional()),
  })
  .partial();
export type ListPeopleWithDeptFilters = z.infer<typeof ListPeopleWithDeptFiltersSchema>;

export const ListPeopleWithDeptSorterSchema = makeSorter(
  z.enum([
    'name',
    'birthday',
    'termination_date',
    'hire_date',
    'rnv_current_balance',
    'year_started_profession',
    'assignments',
    'department.name',
    'salary',
    'foreign_salary',
    'timezoneShortEST',
    'immigration_program',
    'country',
    'state',
    'city',
    'paid_by',
    'contract_type',
    'payment_option',
    'pay_through_date',
    'cultureContact.name',
    'hiredBy.name',
    'slackId',
    'terminationDate',
    'gender',
    'is_billable',
    'remainingPtoDays',
  ]),
);
export type ListPeopleWithDeptSorter = z.infer<typeof ListPeopleWithDeptSorterSchema>;

export const ListPeopleWithDeptQuerySchema = ReportsQuerySchema.extend({
  sorter: ListPeopleWithDeptSorterSchema.optional(),
  filters: ListPeopleWithDeptFiltersSchema.optional(),
  csvColumns: z.string().optional(),
});
export type ListPeopleWithDeptQuery = z.infer<typeof ListPeopleWithDeptQuerySchema>;

const ListPeopleWithDeptClientSchema = ClientMongoSchema.pick({
  _id: true,
  name: true,
});

const ListPeopleWithDeptProjectSchema = ProjectMongoSchema.pick({
  _id: true,
  name: true,
  isSigned: true,
}).extend({
  client: ListPeopleWithDeptClientSchema,
});

const PeopleAssignmentSchema = AssignmentMongoSchema.pick({
  _id: true,
  endDate: true,
}).extend({
  project: ListPeopleWithDeptProjectSchema,
});

const DepartmentSchema = z.object({
  _id: z.string(),
  name: z.string(),
});

const PersonSkillSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    display: z.string(),
    isFavorite: z.boolean(),
    experience: z.number(),
  }),
);

const WarningsSchema = z.object({
  invalidTravelInfo: z.boolean(),
  geekbenchBelowStandards: z.boolean(),
});

const PeoplePersonSchema = PersonSchema.pick({
  name: true,
  email: true,
  timezone: true,
});

const PeoplePersonMongoSchema = PersonMongoSchema.pick({
  _id: true,
  role: true,
  isBillable: true,
  salary: true,
  hireDate: true,
  gravatarURL: true,
});

const PeopleAdditionalSchema = z.object({
  slackId: z.string(),
  terminationDate: dateString.nullable(),
  contractSigned: z.boolean(),
  warnings: WarningsSchema,
  immigrationProgram: z.string().nullable(),
  paidBy: z.string(),
  contractType: z.string(),
  contractStatus: z.string(),
  assignments: z.array(PeopleAssignmentSchema),
  yearStartedProfession: z.number(),
  activeTags: z.array(z.string()).nullable(),
  cultureContactName: z.string(),
  city: z.string(),
  state: z.string().nullable(),
  country: z.string(),
  departmentId: z.string(),
  cultureContactId: z.string(),
  hiredById: z.string(),
  paymentOption: z.string(),
  payThroughDate: dateString.nullable(),
  department: DepartmentSchema,
  cultureContact: z.object({
    _id: z.string(),
    name: z.string(),
  }),
  hiredBy: z.object({
    _id: z.string(),
    name: z.string(),
    surname: z.string(),
  }),
  personSkills: PersonSkillSchema,
  rnvCurrentBalance: z.number(),
  contractTypeTitle: z.string(),
  timezoneShortEST: z.string(),
  remainingPtoDays: z.number().nullable(),
});

export const ListPeopleWithDeptPeopleSchema = PeoplePersonSchema.merge(
  PeoplePersonMongoSchema,
).extend(PeopleAdditionalSchema.shape);

export type ListPeopleWithDeptPeople = z.infer<typeof ListPeopleWithDeptPeopleSchema>;

const CountersList = z.enum([
  'city',
  'contractType',
  'country',
  'cultureContact',
  'department',
  'hiredBy',
  'immigrationProgram',
  'paidBy',
  'state',
  'status',
  'tags',
]);

const CountersSchema = z.object({
  counter: z.number(),
  name: z.string(),
  _id: z.string(),
});

export const ListPeopleWithDeptSchema = z.object({
  skip: z.number(),
  limit: z.number(),
  count: z.number(),
  people: z.array(ListPeopleWithDeptPeopleSchema),
  counters: z.record(CountersList, z.array(CountersSchema)),
});

export type ListPeopleWithDept = z.infer<typeof ListPeopleWithDeptSchema>;

export type ListPeopleWithDeptResponse = ListPeopleWithDept;
