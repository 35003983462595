import z from 'zod';

export const PaginationQuerySchema = z.object({
  skip: z.coerce.number().optional(),
  limit: z.coerce.number().optional(),
  hideOnSinglePage: z.coerce.boolean().nullish(),
  disabled: z.coerce.boolean().nullish(),
  pageSizeOptions: z.array(z.coerce.number()).optional(),
});
export type PaginationQuery = z.infer<typeof PaginationQuerySchema>;

export type PaginatedResponse<T> = {
  skip: number;
  limit: number;
  count: number;
  rows: T[];
};
