import * as api from '@redux/api/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';
import isFunction from 'lodash/isFunction';

function* updateUserWorkingSts(action) {
  try {
    const response = yield call(api.updateUserWorkingStatus, action);
    const normalized = normalize(response.application, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_USER_WORKING_STATUS_UPDATE.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response.message);
    }
    action.onSuccess?.();
  } catch (e) {
    yield put({
      type: constants.APPLICATION_USER_WORKING_STATUS_UPDATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      yield action.onError(e.message || e);
    }
  }
}

export function* watchUpdateUserWorkingStatus() {
  yield takeLatest(constants.APPLICATION_USER_WORKING_STATUS_UPDATE.REQUEST, updateUserWorkingSts);
}
