import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const SFPositionSchema = z.object({
  sf_position_id: z.number(),
  salesforce_id: z.string(),
  name: z.string(),
  dt_start: dateString,
  dt_end: dateString,
  monthly_rate: z.number(),
  quantity: z.number(),
  duration: z.number(),
  project_id: z.string(),
});
export type SFPosition = z.infer<typeof SFPositionSchema>;

export const SFPositionCommentSchema = z.object({
  sf_position_id: z.number(),
  comment_id: z.number(),
});
export type SFPositionComment = z.infer<typeof SFPositionCommentSchema>;
