import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TAppSkillAsc, TAppSkillAscSchema } from '../base';

export const CreateAppSkillAscSchema = TAppSkillAscSchema.pick({
  application_id: true,
  skill_id: true,
}).extend({
  level: z.number().optional(),
  is_favorite: z.boolean().optional(),
});

export type CreateAppSkillAscParams = {};

export type CreateAppSkillAscQuery = {};

export type CreateAppSkillAscRequest = z.infer<typeof CreateAppSkillAscSchema>;

export type CreateAppSkillAscResponse = TAppSkillAsc;

export type CreateAppSkillAscEndpoint = Endpoint<
  Method.POST,
  '/api/v2/sql/applications/skills',
  CreateAppSkillAscResponse,
  CreateAppSkillAscParams,
  CreateAppSkillAscQuery,
  CreateAppSkillAscRequest
>;
