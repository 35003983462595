import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TPersonSkillAscSchema } from '../base';

export const DeletePersonSkillAscSchema = TPersonSkillAscSchema.pick({
  person_id: true,
  skill_id: true,
});

export type DeletePersonSkillAscParams = {};

export type DeletePersonSkillAscQuery = {};

export type DeletePersonSkillAscRequest = z.infer<typeof DeletePersonSkillAscSchema>;

export type DeletePersonSkillAscResponse = void;

export type DeletePersonSkillAscEndpoint = Endpoint<
  Method.DELETE,
  '/api/v2/sql/person/skills',
  DeletePersonSkillAscResponse,
  DeletePersonSkillAscParams,
  DeletePersonSkillAscQuery,
  DeletePersonSkillAscRequest
>;
