import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TPersonSkillAscSchema } from '../base';

export const PersonSkillAscSchema = TPersonSkillAscSchema.pick({
  skill_id: true,
  level: true,
  is_favorite: true,
}).extend({
  name: z.string(),
  path: z.string(),
});

export type PersonSkillAsc = z.infer<typeof PersonSkillAscSchema>;

export type ListPersonSkillAscParams = {
  person_id: string;
};

export type ListPersonSkillAscQuery = {};

export type ListPersonSkillAscRequest = {};

export type ListPersonSkillAscResponse = PersonSkillAsc[];

export type ListPersonSkillAscEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/person/skills/${ListPersonSkillAscParams['person_id']}`,
  ListPersonSkillAscResponse,
  ListPersonSkillAscParams,
  ListPersonSkillAscQuery,
  ListPersonSkillAscRequest
>;
