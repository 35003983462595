import React from 'react';
import { Select, SelectProps } from 'antd';

import { AppSkillAsc, SkillNodeItem } from '@ct-internal/api';
import { useSkillsNode } from './query/queries';

type SkillWithParent = SkillNodeItem & { parent?: string | boolean };

type ParseSkillsToGroupDataProps = {
  skills?: SkillNodeItem[];
  filterOut?: AppSkillAsc[];
  selectedSkills?: number | number[];
  context?: 'job' | 'person';
};

const parseSkillsToGroupData = ({
  skills,
  filterOut,
  selectedSkills,
  context = 'job',
}: ParseSkillsToGroupDataProps): SelectProps['options'] => {
  const findSkillParent = (skillId: number, skills?: SkillNodeItem[]): string | boolean => {
    let parent: SkillNodeItem | undefined;
    let skill: string | boolean = !!skills?.find((sk) => sk.skill_id === skillId);
    if (!skill) {
      skills?.forEach((sk) => {
        if (!skill) {
          skill = findSkillParent(skillId, sk.children);
          if (skill && typeof skill === 'boolean') {
            parent = sk;
          }
        }
      });
    }
    return parent?.name ?? skill;
  };

  const getSlimSkills = (current?: SkillNodeItem[]): SkillWithParent[] => {
    const slim: SkillWithParent[] = [];

    current?.forEach((skill) => {
      slim.push({ ...skill, parent: findSkillParent(skill.skill_id, skills) });
      if (skill.children) {
        slim.push(...(getSlimSkills(skill.children) || []));
      }
    });

    return slim;
  };

  const skillsWithParent = getSlimSkills(skills);

  const groupedByParent = skillsWithParent
    .filter(
      (skill) =>
        ((context === 'job' && skill.is_selectable_job) ||
          (context === 'person' && skill.is_selectable_psn) ||
          (Array.isArray(selectedSkills) && selectedSkills?.includes(skill.skill_id)) ||
          selectedSkills === skill.skill_id) &&
        (!filterOut || !filterOut.some((sk) => sk.skill_id === skill.skill_id)),
    )
    .reduce((acc: { [key: string]: SkillNodeItem[] }, sk) => {
      if (typeof sk.parent === 'string') {
        if (acc[sk.parent]) {
          acc[sk.parent].push(sk);
        } else {
          acc[sk.parent] = [sk];
        }
      }
      return acc;
    }, {});

  return Object.entries(groupedByParent)
    .map(([parent, children]) => ({
      label: parent,
      title: parent,
      options: children
        .map((skill) => ({
          value: skill.skill_id,
          label: skill.name,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

export interface SkillSelectProps extends SelectProps {
  context?: 'job' | 'person';
  filterOut?: AppSkillAsc[];
}

function SelectSkills({ context, filterOut, placeholder, ...props }: SkillSelectProps) {
  const { data } = useSkillsNode({
    options: { refetchOnWindowFocus: false },
  });

  return (
    <Select
      showSearch
      allowClear
      mode="multiple"
      placeholder={placeholder ?? 'Skills'}
      optionFilterProp="label"
      options={parseSkillsToGroupData({
        skills: data?.skills,
        filterOut,
        selectedSkills: props.value,
        context,
      })}
      {...props}
    />
  );
}

export default SelectSkills;
