import { z } from 'zod';

export const TAppSkillAscSchema = z.object({
  application_id: z.string(),
  skill_id: z.number(),
  level: z.number(),
  is_favorite: z.boolean(),
});

export type TAppSkillAsc = z.infer<typeof TAppSkillAscSchema>;
