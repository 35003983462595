import { z } from 'zod';
import { TimeOffRequestSchema } from '../base';

export const TimeOffRequestCreateSchema = TimeOffRequestSchema.pick({
  mongo_id: true,
  person_id: true,
  date_range: true,
  is_flexible: true,
  is_paid: true,
  last_year_days: true,
  status: true,
  category: true,
  working_days: true,
  working_days_last_year: true,
  working_days_next_year: true,
  exceptional_not_counted: true,
  is_from_hire: true,
  approved_by: true,
  dt_approved: true,
  declined_by: true,
  dt_declined: true,
}).partial({
  mongo_id: true,
  last_year_days: true,
  working_days: true,
  working_days_last_year: true,
  working_days_next_year: true,
  exceptional_not_counted: true,
  is_from_hire: true,
  dt_approved: true,
  dt_declined: true,
  approved_by: true,
  declined_by: true,
});
export type TimeOffRequestCreate = z.infer<typeof TimeOffRequestCreateSchema>;
