import { PersonJobLinktreeSchema } from '../base';
import { z } from 'zod';

export const PersonJobLinktreeFindSchema = PersonJobLinktreeSchema.pick({
  person_id: true,
  job_id: true,
})
  .extend({
    people_id_in: z.string().array(),
  })
  .partial();

export type PersonJobLinktreeFind = z.infer<typeof PersonJobLinktreeFindSchema>;
