import { TSkillNodeSchema } from '../skill-node';
import { TJobSchema } from '../jobs';
import { z } from 'zod';

export const JobSkillAscSchema = TJobSchema.pick({
  job_id: true,
}).merge(
  TSkillNodeSchema.pick({
    skill_id: true,
  }),
);
export type JobSkillAsc = z.infer<typeof JobSkillAscSchema>;
