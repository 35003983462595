import { ActionRequestSchema } from '../base';
import { z } from 'zod';
import {
  dateString,
  Endpoint,
  FilterOperations,
  makeSorter,
  Method,
  ReportResponse,
} from '../../../infrastructure';
import { ReportsQuerySchema } from '../../../infrastructure/reports/api';

export const NotificationReportItemSchema = ActionRequestSchema.pick({
  person_id: true,
  action_request_id: true,
  slug: true,
  dt_request: true,
}).extend({
  age: z.number(),
  application_id: z.string(),
  application_name: z.string(),
  application_email: z.string(),
  application_status: z.string(),
  interview_calendar_event_id: z.number(),
  dt_start: z.string(),
  interview_calendar_event_status: z.string(),
  applicant_id: z.string(),
  person_name: z.string(),
});

export type NotificationReportItem = z.infer<typeof NotificationReportItemSchema>;

export const NotificationReportFiltersSchema = z.object({
  person_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  slug: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  dt_interview: z
    .record(z.enum([FilterOperations.BETWEEN]), z.array(dateString).length(2))
    .optional(),
});
export type NotificationReportFilters = z.infer<typeof NotificationReportFiltersSchema>;

export const NotificationReportSortersSchema = makeSorter(
  z.enum(['person_name', 'dt_request', 'slug', 'application_name', 'age']),
);
export type NotificationReportSorters = z.infer<typeof NotificationReportSortersSchema>;

export const NotificationReportQuerySchema = ReportsQuerySchema.extend({
  filters: NotificationReportFiltersSchema.optional(),
  sorter: NotificationReportSortersSchema.optional(),
});

export type NotificationReportQuery = z.infer<typeof NotificationReportQuerySchema>;

export type NotificationReportResponse = ReportResponse<NotificationReportItem>;

export type NotificationReportEndpoint = Endpoint<
  Method.GET,
  '/action-requests/notification-report',
  NotificationReportResponse,
  undefined,
  NotificationReportQuery,
  undefined
>;
