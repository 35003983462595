import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TJobQuestionSchema } from '../table-schemas';

export const CreateJobQuestionRequestSchema = z.object({
  label: TJobQuestionSchema.shape.label,
  description: TJobQuestionSchema.shape.description,
  info: TJobQuestionSchema.shape.info,
  code: z.string(),
  fields: z.record(z.string(), z.any()).array(),
  character_limit_min: z.number().optional(),
  character_limit_max: z.number().optional(),
});

export type CreateJobQuestionRequest = z.infer<typeof CreateJobQuestionRequestSchema>;

export const CreateJobQuestionResponseSchema = z.object({
  question_id: TJobQuestionSchema.shape.question_id,
});
export type CreateJobQuestionResponse = z.infer<typeof CreateJobQuestionResponseSchema>;

export type CreateJobQuestionEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql/job-question`,
  CreateJobQuestionResponse,
  undefined,
  undefined,
  CreateJobQuestionRequest
>;
