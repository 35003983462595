import { useState } from 'react';
import { Space, Select, Form, Card, Typography, Popconfirm, Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  useICEPendingByApplication,
  useICESetConducted,
  useICESetDropped,
  useICESetNoShow,
} from '@src/queries/InterviewCalendarEvent';

export interface PendingInterviewRequestProps {
  application_id: string;
}

export interface PendingInterviewRequestSubmitButtonProps extends ButtonProps {
  isLoading?: boolean;
  handleSubmit: () => void;
}

export function usePendingInterviewRequest({ application_id }: PendingInterviewRequestProps) {
  const [pendingInterviewStatus, setPendingInterviewStatus] = useState('');

  const { data: pendingInterview } = useICEPendingByApplication({
    params: { application_id },
    options: {
      enabled: !!application_id,
    },
  });
  const { mutateAsync: dropICE } = useICESetDropped({});
  const { mutateAsync: conductedICE } = useICESetConducted({});
  const { mutateAsync: noshowICE } = useICESetNoShow({});

  const onSubmit = async () => {
    if (pendingInterview && pendingInterviewStatus) {
      const mutationProp = {
        params: {
          interview_calendar_event_id: pendingInterview.interview_calendar_event_id,
        },
      };

      switch (pendingInterviewStatus) {
        case 'dropped':
          await dropICE(mutationProp);
          break;
        case 'no-show':
          await noshowICE(mutationProp);
          break;
        case 'conducted':
          await conductedICE(mutationProp);
          break;
      }
    }
  };

  const render = pendingInterview ? (
    <Card style={{ marginBottom: 16, borderColor: '#ffc107' }}>
      <Space size={24}>
        <InfoCircleOutlined className="calendly-infobox" />
        <Space direction="vertical" size={16}>
          <Typography.Text>
            This application has an open interview. Please mark it as conducted or dropped before
            scheduling the next interview, otherwise this new interview will be treated as a
            re-schedule of the previous interview.
          </Typography.Text>
          <Form.Item name="pending_interview_status" style={{ margin: 0 }}>
            <Select
              style={{ width: 200 }}
              allowClear
              placeholder="Choose status..."
              options={[
                { label: 'Dropped', value: 'dropped' },
                { label: 'Conducted', value: 'conducted' },
              ]}
              onChange={(value) => setPendingInterviewStatus(value)}
            />
          </Form.Item>
        </Space>
      </Space>
    </Card>
  ) : null;

  const disableSubmitValidation = !pendingInterview || !!pendingInterviewStatus;

  const SubmitButton = ({
    handleSubmit,
    children,
    ...rest
  }: PendingInterviewRequestSubmitButtonProps) => (
    <Popconfirm
      disabled={disableSubmitValidation}
      title="Are you sure that you want to re-schedule the current pending interview?"
      onConfirm={handleSubmit}
    >
      <Button type="primary" onClick={disableSubmitValidation ? handleSubmit : undefined} {...rest}>
        {children}
      </Button>
    </Popconfirm>
  );

  return {
    updatePendingInterviewStatus: onSubmit,
    renderPendingInterviewRequest: render,
    SubmitButton,
  };
}

export default usePendingInterviewRequest;
