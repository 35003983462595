import { z } from 'zod';

export const TPersonSkillAscSchema = z.object({
  person_id: z.string(),
  skill_id: z.number(),
  level: z.number(),
  is_favorite: z.boolean(),
});

export type TPersonSkillAsc = z.infer<typeof TPersonSkillAscSchema>;
