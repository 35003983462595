import { Endpoint, Method } from '../../../infrastructure';
import { Application, ApplicationSchema } from '../base';
import { z } from 'zod';

export const AppSetExcitementRateRequestSchema = z.object({
  excitement_rate: z.number(),
});
export type AppSetExcitementRateRequest = z.infer<typeof AppSetExcitementRateRequestSchema>;
export const AppSetExcitementRateParamsSchema = ApplicationSchema.pick({
  application_id: true,
});
export type AppSetExcitementRateParams = z.infer<typeof AppSetExcitementRateParamsSchema>;
export type AppSetExcitementRateResponse = Application;
export type AppSetExcitementRateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/applications/${AppSetExcitementRateParams['application_id']}/set-excitement-rate`,
  AppSetExcitementRateResponse,
  AppSetExcitementRateParams,
  undefined,
  AppSetExcitementRateRequest
>;
