import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TJobQuestionAnswerSchema } from '../table-schemas';

export const UpsertJobQuestionAnswerRequestSchema = z.object({
  question_id: TJobQuestionAnswerSchema.shape.question_id,
  application_id: TJobQuestionAnswerSchema.shape.application_id,
  answer: TJobQuestionAnswerSchema.shape.answer,
});

export type UpsertJobQuestionAnswerRequest = z.infer<typeof UpsertJobQuestionAnswerRequestSchema>;

export const UpsertJobQuestionAnswerResponseSchema = z.object({
  answer_id: TJobQuestionAnswerSchema.shape.answer_id,
});
export type UpsertJobQuestionAnswerResponse = z.infer<typeof UpsertJobQuestionAnswerResponseSchema>;

export type UpsertJobQuestionAnswerEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql/job-questions/answers`,
  UpsertJobQuestionAnswerResponse,
  undefined,
  undefined,
  UpsertJobQuestionAnswerRequest
>;
