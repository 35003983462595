import { PersonJobLinktreeSchema } from '../base';
import { Method, Endpoint } from '../../../infrastructure';
import { z } from 'zod';

export const PersonJobLinktreeListItemSchema = PersonJobLinktreeSchema;

export type PersonJobLinktreeListItem = z.infer<typeof PersonJobLinktreeListItemSchema>;

export const PersonJobLinktreeFilterSchema = z.object({
  person_id: z.string(),
});
export type PersonJobLinktreeFilter = z.infer<typeof PersonJobLinktreeFilterSchema>;

export const PersonJobLinktreeQuerySchema = z.object({
  filters: PersonJobLinktreeFilterSchema,
});
export type PersonJobLinktreeListQuery = z.infer<typeof PersonJobLinktreeQuerySchema>;

export type PersonJobLinktreeListResponse = PersonJobLinktreeListItem[];

export type PersonJobLinktreeListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/people-job-linktree`,
  PersonJobLinktreeListResponse,
  undefined,
  PersonJobLinktreeListQuery,
  undefined
>;
