import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TJob } from '../table-schemas';
import { JobCreateSchema } from '../crud';
import { JobSkillAscSchema } from '../../job-skill-asc';
import { JobQuestionAscSchema } from '../../job-question-asc';
import { JobVideoQuestionAscSchema } from '../../job-video-question-asc';

export const JobCreateRequestSchema = JobCreateSchema.omit({
  job_id: true,
  slug: true,
  title: true,
  n_sort: true,
}).extend({
  job_skills_asc: z.array(JobSkillAscSchema.pick({ skill_id: true })).nullish(), // list of skill ids
  job_questions_asc: z
    .array(
      JobQuestionAscSchema.pick({
        question_id: true,
        sort: true,
      }),
    )
    .nullish(), // list of question ids
  job_video_questions_asc: z
    .array(
      JobVideoQuestionAscSchema.pick({
        video_question_id: true,
        sort: true,
      }),
    )
    .nullish(), // list of video question ids
  country_group_id: z.number().nullish(),
  country_id: z.string().nullish(),
});

export type JobCreateRequest = z.infer<typeof JobCreateRequestSchema>;

export type JobCreateResponse = TJob;

export type JobCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql/jobs`,
  JobCreateResponse,
  undefined,
  undefined,
  JobCreateRequest
>;
