import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TAppSkillAscSchema } from '../base';

export const DeleteAppSkillAscSchema = TAppSkillAscSchema.pick({
  application_id: true,
  skill_id: true,
});

export type DeleteAppSkillAscParams = {};

export type DeleteAppSkillAscQuery = {};

export type DeleteAppSkillAscRequest = z.infer<typeof DeleteAppSkillAscSchema>;

export type DeleteAppSkillAscResponse = void;

export type DeleteAppSkillAscEndpoint = Endpoint<
  Method.DELETE,
  '/api/v2/sql/applications/skills',
  DeleteAppSkillAscResponse,
  DeleteAppSkillAscParams,
  DeleteAppSkillAscQuery,
  DeleteAppSkillAscRequest
>;
