import { useState } from 'react';
import { PropsWithChildren } from 'react';
import { Card, Drawer, Row, Grid } from 'antd';
import { FiltersSetup } from '../../types';
import { FilterSchema, InferFilterQuery } from '@ct-internal/api';
import FilterForm from './FilterForm';
import FilterButton from './FilterButton';
import './index.css';

const { useBreakpoint } = Grid;

export interface FiltersContainerProps<T extends FilterSchema>
  extends PropsWithChildren,
    FiltersSetup<T> {
  onFilter?: (filters: Partial<InferFilterQuery<T>>) => void;
  filterLabel?: string;
  style?: {};
  className?: string;
  showResetButton?: boolean;
  onReset?: () => void;
  borderedCard?: boolean;
  preventMobileBehavior?: boolean;
}

const Container = <T extends FilterSchema>(props: FiltersContainerProps<T>) => {
  const { className, style, filterLabel = 'Filters', borderedCard, preventMobileBehavior } = props;

  // component state
  const [open, setOpen] = useState(false);

  // is mobile
  const screens = useBreakpoint();
  const isMobile = (screens.xs || screens.sm || screens.md) && !screens.lg;

  return !isMobile || preventMobileBehavior ? (
    <Card
      className={`report-filters ${className || ''}`}
      style={style}
      bordered={borderedCard ?? true}
    >
      <FilterForm {...props} />
    </Card>
  ) : (
    <>
      <FilterButton filterLabel={filterLabel} open={open} setOpen={setOpen} />
      <Drawer
        title="Filters"
        placement="bottom"
        closable={true}
        onClose={() => setOpen(false)}
        open={open}
        key="bottom"
        rootClassName="bottom-search-filter"
      >
        <FilterForm {...props} />
      </Drawer>
    </>
  );
};

Container.Row = Row;

export default Container;
