/* eslint-disable no-unused-vars */

import { z } from 'zod';
import {
  FilterOperations,
  makeSorter,
  PaginatedResponse,
  PaginationQuerySchema,
} from '../../infrastructure';

//eslint-disable-next-line no-shadow
export enum ActionRequestComponentKeys {
  RescheduleInterview = 'reschedule-interview',
  DropInterview = 'drop-interview',
  InterviewConducted = 'mark-interview-as-conducted',
  NoShowInterview = 'no-show-interview',
  SwitchRecruiter = 'switch-recruiter',
  CloseInterview = 'close-interview',
}
//eslint-disable-next-line no-shadow
export enum ActionRequests {
  WaitingForInterviewer = 'waiting-for-interviewer',
  InterviewDeclinedByApplicant = 'interview-declined-by-applicant',
  ApplicantNoResponse = 'applicant-no-response',
  InterviewStaled = 'interview-staled',
  ApplicationRejectedWithPendingInterviews = 'application-rejected-with-pending-interviews',
  NoShowInterviews = 'no-show-interviews',
  InterviewWithoutDecision = 'interview-without-decision',
}
export enum ActionRequestsLabel {
  'waiting-for-interviewer' = 'Pending Interviewer Action',
  'interview-declined-by-applicant' = 'Interview Declined',
  'applicant-no-response' = 'Applicant Unresponsive',
  'interview-staled' = 'Interview Staled',
  'application-rejected-with-pending-interviews' = 'Interviews pending in Rejected Applications',
  'no-show-interviews' = 'Interview No Show',
  'interview-without-decision' = 'Interview Without Decision',
}
export enum ActionRequestDescription {
  'waiting-for-interviewer' = 'Scheduled interviews that are waiting for the interviewer to mark it as conducted or dropped for more than 3 days',
  'interview-declined-by-applicant' = 'Interviews that were declined by the applicant',
  'applicant-no-response' = 'Interviews that the applicant did not respond to',
  'interview-staled' = 'Interviews that the email with calendly link was sent, but applicant did not schedule the interview for more than 3 days',
  'application-rejected-with-pending-interviews' = 'Interviews that are pending, not conducted or dropped, in rejected applications',
  'no-show-interviews' = 'Interviews that the applicant did not show up for',
  'interview-without-decision' = 'Applications that 2nd Interview was scheduled, 5 days already passed but no decision was made for the application',
}

/*
 *  Metadata schemas (used in creation)
 */

export const BaseMetadataSchema = z.object({
  application_id: z.string(),
  interview_date: z.string().datetime(),
  applicant_name: z.string(),
  interview_calendar_event_id: z.number(),
});
export type BaseMetadata = z.infer<typeof BaseMetadataSchema>;

export const ActionRequestMetadataDiscriminatedUnionSchema = z.discriminatedUnion('slug', [
  z.object({
    slug: z.literal(ActionRequests.WaitingForInterviewer),
    metadata: BaseMetadataSchema,
  }),
  z.object({
    slug: z.literal(ActionRequests.InterviewDeclinedByApplicant),
    metadata: BaseMetadataSchema,
  }),
  z.object({
    slug: z.literal(ActionRequests.ApplicantNoResponse),
    metadata: BaseMetadataSchema,
  }),
  z.object({
    slug: z.literal(ActionRequests.InterviewStaled),
    metadata: BaseMetadataSchema,
  }),
  z.object({
    slug: z.literal(ActionRequests.ApplicationRejectedWithPendingInterviews),
    metadata: BaseMetadataSchema,
  }),
  z.object({
    slug: z.literal(ActionRequests.NoShowInterviews),
    metadata: BaseMetadataSchema,
  }),
  z.object({
    slug: z.literal(ActionRequests.InterviewWithoutDecision),
    metadata: BaseMetadataSchema,
  }),
]);

/*
 * Response
 */

export const ActionRequestSchema = z.object({
  action_request_id: z.number(),
  person_id: z.string(),
  email: z.string(),

  slug: z.nativeEnum(ActionRequests),
  group: z.string(),
  metadata: z.record(z.unknown()).nullable(),
  action_components: z.nativeEnum(ActionRequestComponentKeys).array().nullable(),

  dt_request: z.string().datetime(),
  dt_action_completed: z.string().datetime().nullable(),
  dt_action_due: z.string().datetime().nullable(),
  dt_created: z.string().datetime(),
});
export type ActionRequest = z.infer<typeof ActionRequestSchema>;
export type ListActionRequestsResponse = PaginatedResponse<ActionRequest>;

/*
 * Request
 */

export type ListMyActionRequestsResponse = ListActionRequestsResponse;
export const ListMyActionRequestsSorterSchema = makeSorter(z.enum(['dt_request']));

export const ListMyActionRequestsFiltersSchema = z
  .object({
    slug: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    as_admin: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  })
  .partial();
export type ListMyActionRequestsFilters = z.infer<typeof ListMyActionRequestsFiltersSchema>;
export const ListMyActionRequestsQuerySchema = z.object({
  filters: ListMyActionRequestsFiltersSchema.optional(),
  sorter: ListMyActionRequestsSorterSchema.optional(),
  pagination: PaginationQuerySchema.optional(),
});
export type ListMyActionRequestsQuery = z.infer<typeof ListMyActionRequestsQuerySchema>;

export const PatchActionRequestRequestSchema = ActionRequestSchema.pick({
  dt_action_completed: true,
});
export type PatchActionRequestRequest = z.infer<typeof PatchActionRequestRequestSchema>;

export const BaseCreateActionRequestRequestSchema = ActionRequestSchema.pick({
  slug: true,
  dt_request: true,
  dt_action_due: true,
  metadata: true,
}).required({
  dt_request: true,
});

export const CreateActionRequestRequestSchema = BaseCreateActionRequestRequestSchema.and(
  ActionRequestMetadataDiscriminatedUnionSchema,
);
export type CreateActionRequestRequest = z.infer<typeof CreateActionRequestRequestSchema>;

export const ActionRequestBulkSetCloseRequestSchema = z.object({
  action_requests_id: z.number().array(),
});
export type ActionRequestBulkSetCloseRequest = z.infer<
  typeof ActionRequestBulkSetCloseRequestSchema
>;
