import { dateString } from '../../infrastructure';
import { z } from 'zod';

export const LindyAIInterviewSummaryType = ['Summary', 'Review'] as const;
export const LindyAIInterviewSummaryDTOSchema = z.object({
  calendar_event: z.object({
    attendees: z
      .object({
        email: z.string(),
      })
      .array(),
    id: z.string(),
  }),
  type: z.enum(LindyAIInterviewSummaryType),
  summary: z.string(),
});
export type LindyAIInterviewSummaryDTO = z.infer<typeof LindyAIInterviewSummaryDTOSchema>;

export type LindyAIInterviewSummaryReturn = {
  recruiter_name: string;
  application_name: string;
  application_id: string;
};

export const LindyAIMessageStatus = ['Pending', 'Processed'] as const;
export const LindyAIMessageSchema = z.object({
  lindy_ai_message_id: z.number(),
  status: z.enum(LindyAIMessageStatus),
  payload: LindyAIInterviewSummaryDTOSchema,
  dt_created: dateString,
  dt_updated: dateString,
});
export type LindyAIMessage = z.infer<typeof LindyAIMessageSchema>;
