import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

//Base
export const ListLeadStaffingPositionSchema = z.object({
  client_id: z.string(),
  client_name: z.string(),
  lead_id: z.string(),
  lead_name: z.string(),
  position_id: z.string(),
  position_name: z.string(),
  dtplannedstart: dateString,
  dtplannedend: dateString,
  target_cost: z.number(),
  hire_group: z.string(),
  seniority: z.string().nullable(),
  english_level: z.number().nullable(),
  isstaffit: z.boolean(),
  hard_date: z.boolean(),
  revenuerate_id: z.string(),
  revenueamount: z.number(),
  psn_cost: z.number(),
  skills: z.string(),
  skill_color: z.string(),
  skills_array: z.array(z.string()),
  skill_ids: z.array(z.number()),
  skills_array_mongo_id: z.array(z.string()),
  display_color: z.string().nullable(),
  department: z.string().nullable(),
  isbillable: z.boolean().nullable(),
  person_id: z.string().nullable(),
  person_name: z.string().nullable(),
  dthired: dateString.nullable(),
  year_started_profession: z.number().nullable(),
  location: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  country: z.string().nullable(),
  country_code: z.string().nullable(),
  asn_dt_created: dateString.nullable(),
  position_staffing_id: z.number(),
  dt_staffit: dateString,
  status: z.string(),
  dt_recruiting: dateString.nullable(),
  dt_offered: dateString.nullable(),
  dt_filled: dateString.nullable(),

  staffing_person_result: z.string().nullable(),

  person_salary: z.number().nullable(),
  person_salary_currency: z.string().nullable(),
  person_salary_currency_value: z.number().nullable(),
  person_cost: z.number().nullable(),
  person_cost_currency_value: z.string().nullable(),
  contract_type: z.string().nullable(),

  assignment_id: z.string().nullable(),
  primary_candidate_cost: z.number().nullable(),
});

export type ListLeadStaffingPosition = z.infer<typeof ListLeadStaffingPositionSchema>;

//Filters
export const ListLeadStaffingPositionFiltersSchema = z.object({
  lead_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  hire_group: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  position_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  plannedstart: z.record(z.enum([FilterOperations.EQUAL]), dateString).optional(),
  plannedend: z.record(z.enum([FilterOperations.EQUAL]), dateString).optional(),
});
export type ListLeadStaffingPositionFilters = z.infer<typeof ListLeadStaffingPositionFiltersSchema>;

//Query
export const ListLeadStaffingPositionQuerySchema = z.object({
  filters: ListLeadStaffingPositionFiltersSchema.optional(),
});
export type ListLeadStaffingPositionQuery = z.infer<typeof ListLeadStaffingPositionQuerySchema>;

//Response
export type ListLeadStaffingPositionResponse = ListLeadStaffingPosition[];

//Endpoint
export type ListLeadStaffingPositionEndpoint = Endpoint<
  Method.GET,
  '/api/v2/positions/lead-staffing-positions',
  ListLeadStaffingPositionResponse,
  undefined,
  ListLeadStaffingPositionQuery,
  undefined
>;
