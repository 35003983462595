import { useCallback, useState } from 'react';

import { message } from 'antd';

export const downloadFileFromURL = async (fileName: string, fileUrl: string) => {
  try {
    const element = document.createElement('a');
    element.setAttribute('href', fileUrl);
    element.setAttribute('target', '_blank');

    element.click();
  } catch (e) {
    console.error(e);
  }
};

export const useFileExport = (requestUrlFn: Function, fileName: string) => {
  const [isExporting, setIsExporting] = useState(false);

  const downloadFile = useCallback(async () => {
    setIsExporting(true);
    try {
      message.loading('Downloading file...');
      const url = await requestUrlFn();
      await downloadFileFromURL(fileName, url);
      message.success('File downloaded successfully');
    } catch (err: any) {
      message.error(err.message || err);
    }
    setIsExporting(false);
  }, [fileName, requestUrlFn]);
  return { downloadFile, isExporting };
};
