import { PersonJobLinktreeSchema } from '../base';
import { Method, Endpoint } from '../../../infrastructure';
import { z } from 'zod';

export const PersonJobLinktreeWebsiteLinktreeItemSchema = PersonJobLinktreeSchema.pick({
  person_id: true,
  job_id: true,
  person_job_linktree_id: true,
}).extend({
  jobName: z.string(),
  jobSlug: z.string(),
  jobId: z.string(),
  person: z.string(),
  _id: z.string(),
});

export type PersonJobLinktreeWebsiteLinktreeItem = z.infer<
  typeof PersonJobLinktreeWebsiteLinktreeItemSchema
>;
export const PersonJobLinktreeWebsiteLinktreeParamsSchema =
  PersonJobLinktreeWebsiteLinktreeItemSchema.pick({
    person_id: true,
  });
export type PersonJobLinktreeWebsiteLinktreeParams = z.infer<
  typeof PersonJobLinktreeWebsiteLinktreeParamsSchema
>;

export type PersonJobLinktreeWebsiteLinktreeResponse = PersonJobLinktreeWebsiteLinktreeItem[];

export type PersonJobLinktreeWebsiteLinktreeEndpoint = Endpoint<
  Method.GET,
  `/api/v2/people-job-linktree/website-linktree/${PersonJobLinktreeWebsiteLinktreeParams['person_id']}`,
  PersonJobLinktreeWebsiteLinktreeResponse,
  PersonJobLinktreeWebsiteLinktreeParams,
  undefined,
  undefined
>;
