import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { JobUpdateSchema } from '../crud';
import { JobSkillAscSchema } from '../../job-skill-asc';
import { JobQuestionAscSchema } from '../../job-question-asc';
import { JobVideoQuestionAscSchema } from '../../job-video-question-asc';
import { TJob } from '../table-schemas';

export const JobUpdateRequestSchema = JobUpdateSchema.omit({
  job_id: true,
  title: true,
  n_sort: true,
}).extend({
  job_skills_asc: z.array(JobSkillAscSchema.pick({ skill_id: true })).nullish(), // list of skill ids
  job_questions_asc: z
    .array(
      JobQuestionAscSchema.pick({
        question_id: true,
        sort: true,
      }),
    )
    .nullish(), // list of question ids
  job_video_questions_asc: z
    .array(
      JobVideoQuestionAscSchema.pick({
        video_question_id: true,
        sort: true,
      }),
    )
    .nullish(), // list of video question ids
  country_group_id: z.number().nullish(),
  country_id: z.string().nullish(),
});
export type JobUpdateRequest = z.infer<typeof JobUpdateRequestSchema>;

export type JobUpdateResponse = TJob;

export const JobUpdateParamsSchema = z.object({
  job_id: z.string(),
});
export type JobUpdateParams = z.infer<typeof JobUpdateParamsSchema>;

export type JobUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql/jobs`,
  JobUpdateResponse,
  JobUpdateParams,
  undefined,
  JobUpdateRequest
>;
