import { InterviewCalendarEventTransaction } from '../';
import { ActionRequests } from '../action-request';
import { z } from 'zod';

export enum InterviewCalendarEventActions {
  'sendPrepInterviewEmail' = 'sendPrepInterviewEmail',
  'sendApplicantReminderEmail' = 'sendApplicantReminderEmail',
  'sendApplicantInterviewSwapEmail' = 'sendApplicantInterviewSwapEmail',
  'sendApplicantNoResponseSlackMessage' = 'sendApplicantNoResponseSlackMessage',
  'sendDeclinedApplicantSlackMessage' = 'sendDeclinedApplicantSlackMessage',
  'updateGoogleCalendarEventDescription' = 'updateGoogleCalendarEventDescription',
  'sendStaledApplicationSlackMessage' = 'sendStaledApplicationSlackMessage',
  'sendInterviewBookedSlackMessage' = 'sendInterviewBookedSlackMessage',
  'sendInterviewRequestWaitingInterviewerMessage' = 'sendInterviewRequestWaitingInterviewerMessage',
  'pendingInterviewsForRejectedApplications' = 'pendingInterviewsForRejectedApplications',
  'noShowInterviews' = 'noShowInterviews',
  'completeNotifications' = 'completeNotifications',
  'sendInterviewWithoutDecisionNotification' = 'sendInterviewWithoutDecisionNotification',
}

export const InterviewCalendarEventActionSchema = z.object({
  transaction_code: z.nativeEnum(InterviewCalendarEventTransaction).nullish(),
  action_code: z.nativeEnum(InterviewCalendarEventActions),
  interview_calendar_event_id: z.number(),
  slug: z.nativeEnum(ActionRequests).nullish(),
  metadata: z
    .object({
      calendly_reschedule_url: z.string(),
    })
    .partial()
    .optional(),
});

export type InterviewCalendarEventAction = z.infer<typeof InterviewCalendarEventActionSchema>;
