import { Endpoint, Method } from '../../../infrastructure';
import { z } from 'zod';
import { ApplicationSchema } from '../../application/base';

export const ApplicationBulkPatchRequestSchema = z.object({
  application_ids: z.string().array(),
  data: ApplicationSchema.partial(),
});
export type ApplicationBulkPatchRequest = z.infer<typeof ApplicationBulkPatchRequestSchema>;

export type ApplicationBulkPatchResponse = {
  errors: string[];
};

export type ApplicationBulkPatchEndpoint = Endpoint<
  Method.PATCH,
  `/api/v2/applications/bulk`,
  ApplicationBulkPatchResponse,
  undefined,
  undefined,
  ApplicationBulkPatchRequest
>;
