import React, { ReactNode } from 'react';

//Antd
import { ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { useFileExport } from '../../hooks/useFileExport';

export interface FileExportProps {
  fileName: string;
  requestUrlFn: Function;
  children?: ReactNode;
}

export const FileExport = ({ fileName, requestUrlFn, children }: FileExportProps) => {
  const { downloadFile, isExporting } = useFileExport(requestUrlFn, fileName);
  return (
    <Button
      color="default"
      variant="filled"
      icon={<ExportOutlined />}
      onClick={downloadFile}
      disabled={isExporting}
    >
      {children}
    </Button>
  );
};
