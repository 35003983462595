import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const WebSFContactUpdateIpRequestSchema = z.object({
  ip_address: z.string(),
  recipient_id: z.string(),
});
export type WebSFContactUpdateIpRequest = z.infer<typeof WebSFContactUpdateIpRequestSchema>;

export type WebSFContactUpdateIpResponse = { success: boolean };
export type WebSFContactUpdateIpEndpoint = Endpoint<
  Method.POST,
  `/api/v2/web-sf-contact/update-ip`,
  WebSFContactUpdateIpResponse,
  undefined,
  undefined,
  WebSFContactUpdateIpRequest
>;
