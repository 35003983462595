import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TPersonSkillAsc, TPersonSkillAscSchema } from '../base';

export const UpdatePersonSkillAscSchema = TPersonSkillAscSchema.pick({
  person_id: true,
  skill_id: true,
  is_favorite: true,
  level: true,
});

export type UpdatePersonSkillAscParams = {};

export type UpdatePersonSkillAscQuery = {};

export type UpdatePersonSkillAscRequest = z.infer<typeof UpdatePersonSkillAscSchema>;

export type UpdatePersonSkillAscResponse = TPersonSkillAsc;

export type UpdatePersonSkillAscEndpoint = Endpoint<
  Method.PUT,
  '/api/v2/sql/person/skills',
  UpdatePersonSkillAscResponse,
  UpdatePersonSkillAscParams,
  UpdatePersonSkillAscQuery,
  UpdatePersonSkillAscRequest
>;
