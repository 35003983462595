import { PersonJobLinktree } from '../base';
import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const PersonJobLinktreeDeleteParamsSchema = z.object({
  person_job_linktree_id: z.coerce.number(),
});

export type PersonJobLinktreeDeleteParams = z.infer<typeof PersonJobLinktreeDeleteParamsSchema>;

export type PersonJobLinktreeDeleteResponse = PersonJobLinktree;

export type PersonJobLinkTreeDeleteEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/people-job-linktree/${PersonJobLinktreeDeleteParams['person_job_linktree_id']}`,
  PersonJobLinktreeDeleteResponse,
  PersonJobLinktreeDeleteParams,
  undefined,
  undefined
>;
