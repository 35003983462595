import { z } from 'zod';

export const SalaryDisclosureTypeSchema = z.enum(['Law', 'LinkedIn']);

export type SalaryDisclosureType = z.infer<typeof SalaryDisclosureTypeSchema>;

export const WebsiteJobLocationSchema = z.object({
  country: z.string(),
  state: z.string(),
});

export type WebsiteJobLocation = z.infer<typeof WebsiteJobLocationSchema>;
