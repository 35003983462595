import { z } from 'zod';
import { JobWebiteRegionVisibilitySchema } from '../base';

export const JobWebiteRegionVisibilityUpsertSchema = JobWebiteRegionVisibilitySchema.pick({
  job_id: true,
  country_group_id: true,
  country_id: true,
}).partial({
  country_group_id: true,
  country_id: true,
});
export type JobWebiteRegionVisibilityUpsert = z.infer<typeof JobWebiteRegionVisibilityUpsertSchema>;
