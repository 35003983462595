import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../../infrastructure';
import { TJobQuestionSchema } from '../table-schemas';

export const JobQuestionWebsiteListItemSchema = TJobQuestionSchema.pick({
  question_id: true,
  label: true,
  description: true,
  fields: true,
  info: true,
  code: true,
}).extend({
  updated_at: dateString,
  created_at: dateString,
  usedBy: z.number(),
});
export type JobQuestionWebsiteListItem = z.infer<typeof JobQuestionWebsiteListItemSchema>;

export const JobQuestionWebsiteListParamSchema = z.object({
  job: z.string(),
});
export type JobQuestionWebsiteListParam = z.infer<typeof JobQuestionWebsiteListParamSchema>;

export type JobQuestionWebsiteListResponse = {
  questions: JobQuestionWebsiteListItem[];
};

export type JobQuestionWebsiteListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/jobs/new/website/questions/${JobQuestionWebsiteListParam['job']}`,
  JobQuestionWebsiteListResponse,
  JobQuestionWebsiteListParam,
  undefined,
  undefined
>;
