import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TAppSkillAsc, TAppSkillAscSchema } from '../base';

export const UpdateAppSkillAscSchema = TAppSkillAscSchema.pick({
  application_id: true,
  skill_id: true,
  is_favorite: true,
  level: true,
});

export type UpdateAppSkillAscParams = {};

export type UpdateAppSkillAscQuery = {};

export type UpdateAppSkillAscRequest = z.infer<typeof UpdateAppSkillAscSchema>;

export type UpdateAppSkillAscResponse = TAppSkillAsc;

export type UpdateAppSkillAscEndpoint = Endpoint<
  Method.PUT,
  '/api/v2/sql/applications/skills',
  UpdateAppSkillAscResponse,
  UpdateAppSkillAscParams,
  UpdateAppSkillAscQuery,
  UpdateAppSkillAscRequest
>;
