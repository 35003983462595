import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TSkillNode, TSkillNodeSchema } from '../base';

export const CreateSkillNodeSchema = TSkillNodeSchema.pick({
  name: true,
  is_selectable_job: true,
  is_selectable_psn: true,
  is_selectable_pos: true,
}).extend({
  is_deep_skill: z.boolean().optional(),
  parent_skill_id: z.number().optional(),
});

export type CreateSkillNodeParams = {};

export type CreateSkillNodeQuery = {};

export type CreateSkillNodeRequest = z.infer<typeof CreateSkillNodeSchema>;

export type CreateSkillNodeResponse = Omit<TSkillNode, 'level'>;

export type CreateSkillNodeEndpoint = Endpoint<
  Method.POST,
  '/api/v2/sql/skill-node',
  CreateSkillNodeResponse,
  CreateSkillNodeParams,
  CreateSkillNodeQuery,
  CreateSkillNodeRequest
>;
