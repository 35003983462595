import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TSkillNode, TSkillNodeSchema } from '../base';

export const UpdateSkillNodeSchema = TSkillNodeSchema.pick({
  name: true,
  is_deep_skill: true,
  is_selectable_job: true,
  is_selectable_psn: true,
  is_selectable_pos: true,
});

export const UpdateSkillNodeParamsSchema = z.object({
  skill_id: z.string().regex(/^\d+$/, 'Invalid skill id').transform(Number),
});

export type UpdateSkillNodeParams = z.infer<typeof UpdateSkillNodeParamsSchema>;

export type UpdateSkillNodeQuery = {};

export type UpdateSkillNodeRequest = z.infer<typeof UpdateSkillNodeSchema>;

export type UpdateSkillNodeResponse = TSkillNode;

export type UpdateSkillNodeEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql/skill-node/${UpdateSkillNodeParams['skill_id']}`,
  UpdateSkillNodeResponse,
  UpdateSkillNodeParams,
  UpdateSkillNodeQuery,
  UpdateSkillNodeRequest
>;
