import { PersonJobLinktree } from '../base';
import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const PersonJobLinktreeAllHotJobsRequestSchema = z.object({
  person_id: z.string(),
});
export type PersonJobLinktreeAllHotJobsRequest = z.infer<
  typeof PersonJobLinktreeAllHotJobsRequestSchema
>;

export type PersonJobLinktreeAllHotJobsResponse = PersonJobLinktree[];

export type PersonJobLinkTreeAllHotJobsEndpoint = Endpoint<
  Method.POST,
  '/api/v2/people-job-linktree/all-hot-jobs',
  PersonJobLinktreeAllHotJobsResponse,
  undefined,
  undefined,
  PersonJobLinktreeAllHotJobsRequest
>;
